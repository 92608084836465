<script>
import { Bar } from 'vue-chartjs';
import { mapState } from 'vuex';
import { getFormattedCurrentDate } from '@/utils.js';

export default {
  extends: Bar,
  props: {
    datasets: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chartdata: {},
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          mode: 'index',
          intersect: false
        },
        hover: {
          mode: 'index',
          intersect: false,
          animationDuration: 0
        },
        scales: {
          yAxes: [
            {
              id: 'y-axis-1',
              position: 'left',
              ticks: {
                display: true
              },
              scaleLabel: {
                display: true,
                labelString: this.datasets[0].label
              }
            },
            {
              id: 'y-axis-2',
              display: this.datasets.length > 1,
              position: 'right',
              ticks: {
                display: true
              },
              scaleLabel: {
                display: true,
                labelString: this.datasets.length > 1 ? this.datasets[1].label : ''
              },
              gridLines: {
                drawTicks: false,
                display: false
              }
            }
          ],
          xAxes: [
            {
              id: 'x-axis-1',
              ticks: {
                display: true
              },
              gridLines: {
                drawTicks: true,
                display: true
              }
            }
          ]
        }
      }
    };
  },
  computed: {
    ...mapState(['table_chart'])
  },
  mounted() {
    const combinedLabels = [];
    const yAxisArray = [];
    const udmArray = [];

    if (this.datasets.length > 1) {
      //Popola il vettore udmArray con le stringhe delle udm
      Array.from(
        new Set(
          this.datasets
            .map((dataset) => {
              const match = dataset.label.match(/\(([^)]+)\)/);

              return match ? match[1] : null;
            })
            .filter((label) => label !== null)
        )
      ).slice(0, 2).forEach(udm => {
        udmArray.push(udm);
      });

      //Popola il vettore combinedLabels
      udmArray.forEach((udm) => {
        const matchingLabels = this.datasets
          .filter((dataset) => dataset.label.includes(`(${udm})`))
          .map((dataset) => dataset.label);

        const joinedLabels = matchingLabels.join(' / ');
        combinedLabels.push(joinedLabels);
      });

      //Popola il vettore yAxisArray
      this.datasets.forEach((data) => {
        const index = udmArray.findIndex(elem => data.label.includes(`(${elem})`)) || 0;
        yAxisArray.push(`y-axis-${index + 1}`);
      });

      this.options.scales.yAxes = [];
      udmArray.forEach((udm, i) => {
        this.options.scales.yAxes[i] = {
          display: true,
          id: `y-axis-${i + 1}`,
          position: i == 0 ? 'left' : 'right',
          scaleLabel: {
            display: true,
            labelString: combinedLabels[i]
          },
          gridLines: {
            display: i == 0,
            drawTicks: i == 0
          }
        };
      });

    } else {
      this.datasets.forEach((e, i) => yAxisArray.push(`y-axis-${i + 1}`));
    }

    this.chartdata = {
      labels: this.table_chart.rasterVectorChartLabels.map(time => {
        const timeFormat = time.includes('T') ? getFormattedCurrentDate(new Date(time)) : time.replace('Z', '');

        return timeFormat.replace(/-/g, '/');
      }),
      datasets: this.table_chart.rasterVectorChartDataSetBar.map((data, index) => {
        return {
          ...data,
          yAxisID: yAxisArray[index]
        };
      })
    };
    this.$emit('chart_data', this.chartdata);
    this.separateDaysPlugin();
    this.renderChart(this.chartdata, this.options);
  },
  methods: {
    separateDaysPlugin() {
      this.addPlugin({
        beforeDatasetsDraw: chart => {
          const ctx = chart.ctx;
          const xAxis = chart.scales['x-axis-1'];
          const yAxis = chart.scales['y-axis-1'];
          const tickGap = xAxis.getPixelForTick(1) - xAxis.getPixelForTick(0);

          ctx.save();
          let previousDate = null;
          let currentBackgroundColor = 'rgba(255, 255, 255, 0)';
          xAxis.ticks.forEach((tick, index) => {
            if (index === 0) {
              previousDate = tick.split(' ')[1];

              return;
            }

            const currentDate = tick.split(' ')[1];
            const sameDay = previousDate === currentDate;
            previousDate = currentDate;

            if (!sameDay) {
              currentBackgroundColor = currentBackgroundColor === 'rgba(255, 255, 255, 0)'
                ? 'rgba(230, 246, 236, 1)'
                : 'rgba(255, 255, 255, 0)';
            }

            const startX = xAxis.getPixelForTick(index - 1) + tickGap / 2;
            const endTickGap = tickGap * 0.5;
            const endX = xAxis.getPixelForTick(index) + endTickGap;

            ctx.fillStyle = currentBackgroundColor;
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillRect(startX, yAxis.top, endX - startX, yAxis.bottom - yAxis.top);
          });
          ctx.restore();
        }
      });
    }
  }
};
</script>
